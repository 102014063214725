

























































































































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";
import Debounce from "lodash/debounce";

import BaseComponent from "@/components/base-component.vue";
import SearchRunner from "@/components/home/search-runner.vue";
import CapturePhoto from "@/components/capture-photo/capture-photo.vue";
import Loader from "@/components/loader.vue";
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconSearch from "@/components/icons/icon-search.vue";
import IconSearch2 from "@/components/icons/icon-search2.vue";
import IconCheckBold from "@/components/icons/icon-check-bold.vue";
import IconIncorrect from "@/components/icons/icon-incorrect.vue";
import IconSelfie from "@/components/icons/icon-selfie.vue";
import IconSelfie2 from "@/components/icons/icon-selfie2.vue";
import IconRight from "@/components/icons/icon-right.vue";
import IconRight2 from "@/components/icons/icon-right2.vue";
import { SearchState } from "@/store/modules/search";
import { EventsState } from "@/store/modules/events";
import { CaptureState } from "@/store/modules/capture";
import { SettingsState } from "@/store/modules/settings";
import { ProductTypes } from "@/product-types";

@Component({
  name: "SearchFormShort",
  props: {
    eventId: String,
    externalEventId: String,
    eventName: String,
    eventDate: String,
    tagged: String,
    taggedOnly: Boolean,
    isEventPage: Boolean,
    isAllPhotosVisible: Boolean,
  },
  components: {
    SearchRunner,
    CapturePhoto,
    Loader,
    BaseIcon,
    IconSearch,
    IconSearch2,
    IconCheckBold,
    IconIncorrect,
    IconSelfie,
    IconSelfie2,
    IconRight,
    IconRight2,
  },
})
export default class SearchFormShort extends BaseComponent {

  private readonly searchState: SearchState = getModule(SearchState, this.$store);
  private readonly eventsState: EventsState = getModule(EventsState, this.$store);
  private readonly captureState: CaptureState = getModule(CaptureState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  private debouncedDoSearch: any = null;

  productTypes: any = ProductTypes;

  recent: any[] = [];
  recentExternalEventId: string = "";

  runnersContainerId: string = "";

  eventId_: string = "";
  eventName_: string = "";
  eventDate_: string = "";
  isEventSelected: boolean = true;

  runnerNum: string = "";
  runnerName: string = "";
  runnerLastName: string = "";
  runnerFirstName: string = "";
  runnerFrameValues: any = {};
  isRunnerSelected: boolean = false;
  isRunnerError: boolean = false;
  isRunnerSearching: boolean = false;

  hasResults: boolean = false;

  byFace: boolean = false;

  runnersDialog: boolean = false;
  runnersDialogKey: string = "";
  runnersHeight: string = "auto";

  captureDialog: boolean = false;

  input: any = null;
  selectedFile: File | null = null;
  redrawImage: boolean = false;
  searchResult: string = "";

  get theme(): any {
    return this.settingsState.theme;
  }

  get runnerFullString(): string {
    if (!this.runnerName) return "";
    return this.runnerName + " (№" + this.runnerNum + ")";
  }

  get isTagged(): boolean {
    return this.$props.tagged == "true";
  }

  get photosPerPage(): number {
    if (this.smOnly || this.mdOnly) return 12;
    return 50;
  }

  getFullName(item: any): string {
    return (item.surname || "") + " " + (item.firstName || "");
  }

  getItemPhotos(item: any): string[] {
    if (!item) return [];
    
    const type: string = item.product.productType;
    if (type == this.productTypes.frame) return [];

    let photoIds: string[] = [];
    if (type == this.productTypes.photo) {
      photoIds = [ item.product.photo.photoId ];  
    } else if (type == this.productTypes.photos_with_me) {
      photoIds = item.product.photos.map((i: any) => {
        return i.photoId;
      });
    }

    return photoIds;
  }

  getRecentSearches(): any[] {
    const recent: any[] = this.recent.filter((i: any) => i.event.eventId == this.eventId_)
    if (recent.length > 0) {
      this.recentExternalEventId = recent[0].event.externalEventId;
      return recent[0].searches;
    }
    
    return [];
  }

  getInputStyles(): Object {
    const styles: any = {};
    if (this.runnersDialog) {
      styles['opacity'] = '0';
    }

    return styles;
  }

  @Watch("runnersDialog")
  async onRunnersDialogChanged(): Promise<void> {
    if (this.runnersDialog && (this.smOnly || this.mdOnly)) {
      this.settingsState.setModalActive(true);
    } else {
      this.settingsState.setModalActive(false);
    }
  }

  @Watch("captureDialog")
  async onCaptureDialogChanged(): Promise<void> {
    if (this.captureDialog) {
      this.settingsState.setModalActive(true);
    } else {
      this.settingsState.setModalActive(false);
    }
  }

  async onViewAllPhotos(externalEventId: string): Promise<void> {
    if (!externalEventId) return;
    
    await this.$router.push({
      name: "event-photos",
      params: {
        id: externalEventId,
      }
    });
  }

  async onGoRecent(search: any): Promise<void> {
    if (!search) return;
    
    if (this.$route.name === "event") {
      this.$store.state.history = ["home", "event"];
    }

    this.settingsState.reachGoal('recently_search');
    this.settingsState.reachGoal(search.faceUrl ? 'media_selfie_uploaded' : 'media_member_selected');

    await this.$router.push({
      name: "search-from-url",
      params: {
        id: this.recentExternalEventId,
        number: search.faceUrl ? search.selfieId + '+' + search.personId : search.startNumber,
      }
    })
  }

  async updateState(): Promise<void> {
    if (this.$props.eventId && this.$props.eventName) {
      this.eventId_ = this.$props.eventId;
      this.eventName_ = this.$props.eventName;
      this.eventDate_ = this.$props.eventDate;
      this.isEventSelected = true;
    } else {
      this.eventId_ = "";
      this.eventName_ = "";
      this.eventDate_ = "";
      this.isEventSelected = false;
    }
  }

  async onEditRunner(): Promise<void> {
    this.runnersDialog = true;
    setTimeout(() => { this.runnersDialogKey = Math.random().toString(); }, 100);
  }

  async onRunnerChanged(hasResults: boolean): Promise<void> {
    if (hasResults) {
      setTimeout(async () => await this.updateRunnersHeight(), 100);
    } else {
      this.runnersHeight = "40px";
    }
    this.hasResults = hasResults;
  }

  async updateRunnersHeight(): Promise<void> {
    const r = document.getElementById(this.runnersContainerId);
    const f = document.getElementById('runnerSearchForm' + this.runnersDialogKey);
    if (r == null || f == null) return;

    const t = r.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;
    const height = windowHeight - t - 16;

    const formHeight = f.offsetHeight;

    this.runnersHeight = Math.min(height, formHeight) + "px";
  }

  async onRunnerSelected(value: { 
    competitorId: string, 
    surname?: string, 
    firstName?: string, 
    number: string,
    time?: string,
    position?: number,
    distance?: string,
  }): Promise<void> {
    this.runnersDialog = false;
    this.isRunnerError = false;
    this.runnerNum = value.number;
    this.runnerName = this.getFullName(value);
    this.runnerLastName = value.surname || "";
    this.runnerFirstName = value.firstName || "";
    this.runnerFrameValues = {
      competitorId: value.competitorId || "",
      surname: value.surname || "",
      firstName: value.firstName || "",
      time: value.time || "",
      position: value.position ? value.position.toString() : "",
      distance: value.distance || "",
    };
    this.isRunnerSelected = true;

    this.settingsState.reachGoal("media_member_selected");

    await this.onSearchByTag();
  }

  async onSearchByTag(): Promise<void> {
    this.byFace = false;
    this.isRunnerSearching = true;
    this.debouncedDoSearch();
  }

  async onSearchSelfie(): Promise<void> {
    if (!this.input) return;

    this.input.setAttribute("capture", "camera");
    await this.onSearchByFace();
  }

  async onSearchUpload(): Promise<void> {
    if (!this.input) return;

    this.input.removeAttribute("capture");
    await this.onSearchByFace();
  }

  async onSearchByFace(): Promise<void> {
    await this.setPictureHandlers();
    if (!this.input) return;

    if (this.captureDialog) {
      this.captureDialog = false;
    } else {
      this.input.removeAttribute("capture");
    }

    this.searchResult = "searching";
    this.byFace = true;
    this.input.click();
  }

  async onPictureChange(): Promise<void> {
    if (!this.input) return;

    const curFiles = this.input.files;

    if (curFiles.length === 0) {
      this.selectedFile = null;
      this.redrawImage = false;
      this.$store.state.selectedFile = null;
      // console.log('No files currently selected for upload');
    } else {
      await this.loadImage(curFiles[0]);
      this.redrawImage = false;
      this.captureDialog = true;
      this.redrawImage = false;
      this.selectedFile = this.captureState.file;
      this.debouncedDoSearch();

      this.settingsState.reachGoal("media_selfie_uploaded");
      // console.log('Selected file: ' + curFiles[0].name);
    }
  }

  async loadImage(selectedFile: File): Promise<void> {
    await this.captureState.loadImage(selectedFile);
    await this.captureState.loadFile(this.captureState.image.image.src);

    this.$store.state.selectedFile = this.captureState.file;
  }

  async doSearch(): Promise<void> {
    if (this.byFace) await this.searchByFace();
    else await this.searchByTag();
  }

  async searchByTag(): Promise<void> {
    const payload: any = {
      eventId: this.eventId_,
      token: '',
      pagination: { offset: 0, count: this.photosPerPage },
    };

    const result: any = await this.getNewSearchToken({
      eventId: this.eventId_,
      payload: { startNumber: this.runnerNum },
    });
    if (result) {
      payload.token = result.value;
    } else {
      this.isRunnerSearching = false;
      return;
    }

    await this.searchState.searchByTag(payload);

    if (this.searchState.searchError) {
      this.isRunnerSearching = false;
      return;
    }

    if (this.searchState.photos.length > 0) {
      await this.showResults();
      // console.log('= found');
    } else {
      this.isRunnerError = true;
      this.$emit('updated', this.isRunnerError);
      // console.log('= not found');
    }

    this.isRunnerSearching = false;
  }

  async searchByFace(): Promise<void> {
    this.redrawImage = true;

    await this.searchState.recognizePhoto({ file: this.selectedFile, albumId: this.eventId_ });
    if (this.searchState.searchError) {
      if (this.searchState.responseCode == 413) {
        this.searchResult = "too-big-file";
        return;
      } else if (this.searchState.responseCode == 500) {
        const result: boolean = await this.repeatSearchByFace();
        if (!result) return;
      } else {
        this.searchResult = "error";
        return;
      }
      this.captureDialog = false;
      return;
    }

    if (this.searchState.persons.length == 1) {

      const personId: string = this.searchState.persons[0].personId;
      if (!personId) {
        this.captureDialog = false;
        return;
      }
      if (personId === "undefined") {
        await this.showNotFound();
        this.captureDialog = false;
        return;
      }
      
      const payload: any = {
        eventId: this.eventId_,
        token: '',
        pagination: { offset: 0, count: this.photosPerPage },
      };

      const result: any = await this.getNewSearchToken({
        eventId: this.eventId_,
        payload: { personId },
      });
      if (result) {
        payload.token = result.value;
      } else {
        this.searchResult = "error";
        return;
      }

      await this.searchState.searchByTag(payload);

      if (this.searchState.photos.length > 0) {
        await this.showResults();
        this.captureDialog = false;
        // console.log('= found');
      } else {
        await this.showNotFound();
        this.captureDialog = false;
        // console.log('= found, but no pictures');
      }
    } else if (this.searchState.persons.length == 0) {
      this.searchResult = "no-face";
    } else if (this.searchState.persons.length > 1) {
      this.searchResult = "many-people";
    } else {
      this.captureDialog = false;
    }
  }

  async repeatSearchByFace(): Promise<boolean> {
    await this.searchState.recognizePhoto({ file: this.selectedFile, albumId: this.eventId_ });
    
    if (this.searchState.searchError && this.searchState.responseCode == 413) {
      this.searchResult = "too-big-file";
      return false;
    } else if (this.searchState.searchError && this.searchState.responseCode == 500) {
      this.searchResult = "error";
      return false;
    } else if (this.searchState.searchError) {
      this.searchResult = "error";
      return false;
    }

    return true;
  }

  async getNewSearchToken(payload: any): Promise<any> {
    const tokenResult = await this.searchState.getToken(payload);
    if (tokenResult.status !== 200) return null;
    return this.searchState.searchToken;
  }

  async showResults(): Promise<void> {
    this.$store.state.history.push("home");

    const faceImage = this.byFace ? (this.searchState.persons[0] ? this.searchState.persons[0].faceUrl : "") : "";
    const personId = this.byFace ? (this.searchState.persons[0] ? this.searchState.persons[0].personId : "") : "";

    await this.eventsState.getEventById(this.eventId_);

    let num: string = "";
    if (this.byFace) {
      num = this.searchState.selfieId + "+" + personId;
    } else {
      num = this.runnerNum;
    }

    if (this.$route.name === "event") {
      this.$store.state.history = ["home", "event"];
    }
    await this.$router.push({
      name: "search-from-url",
      params: {
        id: this.eventsState.event.externalEventId,
        number: num,
        eventId: this.eventId_,
        eventName: this.eventName_,
        eventDate: this.eventDate_,
        runnerNum: this.runnerNum,
        runnerFrameValues: JSON.stringify(this.runnerFrameValues),
        byFace: this.byFace.toString(),
        faceImage: faceImage,
        selfieId: this.byFace ? this.searchState.selfieId : "",
        personId: personId,
        photos: JSON.stringify(this.searchState.photos),
        notFound: "false"
      }
    });
  }

  async showNotFound(): Promise<void> {
    this.$store.state.history.push("home");

    const faceImage = this.byFace ? (this.searchState.persons[0] ? this.searchState.persons[0].faceUrl : "") : "";
    const personId = this.byFace ? (this.searchState.persons[0] ? this.searchState.persons[0].personId : "") : "";

    await this.eventsState.getEventById(this.eventId_);

    let num: string = "";
    if (this.byFace) {
      num = this.searchState.selfieId + "+" + personId;
    } else {
      num = this.runnerNum;
    }

    if (this.$route.name === "event") {
      this.$store.state.history = ["home", "event"];
    }
    await this.$router.push({
      name: "search-from-url",
      params: {
        id: this.eventsState.event.externalEventId,
        number: num,
        eventId: this.eventId_,
        eventName: this.eventName_,
        eventDate: this.eventDate_,
        runnerNum: this.runnerNum,
        runnerFrameValues: "",
        byFace: this.byFace.toString(),
        faceImage: faceImage,
        selfieId: this.byFace ? this.searchState.selfieId : "",
        personId: personId,
        photos: JSON.stringify([]),
        notFound: "true"
      }
    });
  }

  async setPictureHandlers(): Promise<void> {
    const input: any = document.getElementById("cameraInput");
    if (!input) return;
    this.input = input;
    input.onchange = this.onPictureChange;
  }

  async loadRecentSearches(): Promise<void> {
    const itemName = "eventRecentSearch";
    const value = localStorage.getItem(itemName);
    if (value) {
      try {
        const parsed: any[] = JSON.parse(value);
        this.recent = this.getGroupedByEvent(parsed);
      } catch (e) {
        this.recent = [];
      }
    } else {
      this.recent = [];
    }
  }

  getGroupedByEvent(parsed: any[]): any[] {
    const grouped: any[] = [];

    for (let i = 0; i < parsed.length; i++) {
      if (grouped.length == 0) {
        grouped.push({ event: parsed[i].event, searches: [parsed[i].search] });
        continue;
      }

      if (grouped.length == 1 && grouped[0].event.eventId == parsed[i].event.eventId) {
        if (grouped[0].searches.length < 3) {
          grouped[0].searches.push(parsed[i].search);
          continue;
        } else {
          continue;
        }
      }

      if (grouped.length == 1 && grouped[0].event.eventId != parsed[i].event.eventId) {
        grouped.push({ event: parsed[i].event, searches: [parsed[i].search] });
        continue;
      }

      const isFirst: boolean = grouped[0].event.eventId == parsed[i].event.eventId;
      const isSecond: boolean = grouped[1].event.eventId == parsed[i].event.eventId;
      
      if (grouped.length == 2 && (isFirst || isSecond)) {
        const index: number = isFirst ? 0 : 1;
        if (grouped[index].searches.length < 3) {
          grouped[index].searches.push(parsed[i].search);
          continue;
        } else {
          continue;
        }
      }

      if (grouped.length == 2 && (!isFirst && !isSecond)) {
        continue;
      }
    }

    return grouped;
  }

  async created(): Promise<void> {
    this.runnersContainerId = "runners" + Math.random().toString();
    
    this.debouncedDoSearch = Debounce(this.doSearch, 500);

    await this.updateRunnersHeight();

    window.addEventListener("resize", async () => {
        await this.updateRunnersHeight();
      }
    );
  }

  async mounted(): Promise<void> {
    await this.updateState();

    await this.updateRunnersHeight();

    await this.loadRecentSearches();
  }

}
